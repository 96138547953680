import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [

    {
        path: '/',
        name: 'login',
        component: () => import(/* webpackChunkName: "about" */ '../views/login/Login')
    },
    {
        path: '/Index',
        name: 'index',
        component: () => import(/* webpackChunkName: "about" */ '../components/Index'),
        children: [
            // {
            //     path: '/ApiPort',
            //     name: 'api管理',
            //     meta: {
            //         title: 'api管理'
            //     },
            //     component: () => import('../components/system/ApiPort')
            // },
            // {
            //   path:"/User",
            //   name:"user",
            //   meta:{
            //     title:'用户管理'
            //   },
            //   component:()=>import('../components/system/User')
            // },
            // {
            //   path:"/Role",
            //   name:"role",
            //   meta:{
            //     title:'角色管理'
            //   },
            //   component:()=>import('../components/system/Role')
            // },
        ]
    }
]

const router = new VueRouter({
    mode: 'history',
    // base: process.env.BASE_URL,
    routes
})

export function resetRouter() {
    // router.matcher = new VueRouter({
    //     mode: 'history',
    //     routes: []
    // }).matcher
    router.matcher=new VueRouter({
        mode:'history',
        routes:[]
    }).matcher
}

const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(to) {
    return VueRouterPush.call(this, to).catch(err => err)
}
export default router
