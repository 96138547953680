<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {
  name: "App",
  components: {},
  data() {
    return {
      userObj: JSON.parse(localStorage.getItem("userObj"))
    }
  },
  watch: {
    '$store.state.menu': {
      handler(val, old) {
        if (!old && this.userObj && this.userObj.user.loginName) {
          this.$store.commit("setRouter", val)
        }
      },
      immediate: true
    }
  }
}
</script>

<style>
#app {
  height: 100%;
}

html, body {
  height: 100%;
}
</style>
