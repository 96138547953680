import Vue from "vue";
import Vuex from 'vuex'
import router, {resetRouter} from "@/router";
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

function addNewRouter(menuList) {
    let routes = router.options.routes
    routes.forEach(routeItem => {
        if (routeItem.path == "/Index") {
            menuList.forEach(menu => {
                let childRoute = {
                    path: "/" + menu.menuIndex,
                    name: menu.name,
                    meta: {
                        title: menu.name
                    },
                    component: () => import('../' + menu.component)
                }
                if (childRoute.path !== '/' && childRoute.path !== '/null' && childRoute.path !== null){
                    routeItem.children.push(childRoute)
                }
                /*二级路由添加*/
                if (menu.childMenuList.length > 0) {
                    menu.childMenuList.forEach(twoMenu => {
                        let twoChildRoute = {
                            path: "/" + twoMenu.menuIndex,
                            name: twoMenu.name,
                            meta: {
                                title: twoMenu.name
                            },
                            component: () => import('../' + twoMenu.component)
                        }
                        if (twoChildRoute.path !== '/' && twoChildRoute.path !== '/null' && twoChildRoute.path !== null){
                            routeItem.children.push(twoChildRoute)
                        }
                        /*三级路由*/
                        if (twoMenu.childMenuList.length > 0) {
                            twoMenu.childMenuList.forEach(threeMenu => {
                                let threeChildRoute = {
                                    path: "/" + threeMenu.menuIndex,
                                    name: threeMenu.name,
                                    meta: {
                                        title: threeMenu.name
                                    },
                                    component: () => import('../' + threeMenu.component)
                                }
                                if (threeChildRoute.path !== '/' && threeChildRoute.path !== '/null' && threeChildRoute.path !== null){
                                    routeItem.children.push(threeChildRoute)
                                }
                            })
                        }
                    })
                }
            })
        }
    })
    resetRouter()
    routes.forEach(route =>{
        router.addRoute(route)
    })

}

export default new Vuex.Store({
    state: {
        menu: []
    },
    mutations: {
        setMenu(state, menuList) {
            state.menu = menuList
            //添加路由
            addNewRouter(menuList)
        },
        setRouter(state, menuList) {
            //添加路由
            addNewRouter(menuList)
        }
    },
    getters: {
        getMenu(state) {
            return state.menu
        }
    },
    plugins: [createPersistedState()]
})